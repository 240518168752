<template>
  <el-tooltip
    effect="dark"
    placement="bottom"
    content="举手"
    :visible-arrow="false"
    popper-class="default-tooltip"
  >
    <i
      class="iconfont icon-jushoubeifen"
      :class="{
        'disabled': !isOpenGlobalHandsUp || myHandDisabled,
        'active': showAgreeHandsToolTip
      }"
      @click="handleHandsUp"
    />
  </el-tooltip>
</template>

<script>
import { setHandsUp } from '@/services/room';

export default {
  name: 'HandsUpButton',
  props: {
    myHandDisabled: {
      type: Boolean,
      default: false,
    },
    isOpenGlobalHandsUp: {
      type: Boolean,
      required: true,
    },
    showAgreeHandsToolTip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      roomId: this.$route.params.id,
    };
  },

  methods: {
    async handleHandsUp() {
      if (!this.isOpenGlobalHandsUp || this.myHandDisabled) return;
      await setHandsUp({
        liveRoomId: this.roomId,
        type: 1,
      });
      this.$emit('handleHandsUp');
    },
  },
};

</script>
